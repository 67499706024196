import Pact from 'pact-lang-api'

// chain that contract lives on
const CHAINID = process.env.NEXT_PUBLIC_MARMALADE_CHAIN_ID
const NETWORKID = process.env.NEXT_PUBLIC_KADENA_NETWORK_ID
const NETWORK = NETWORKID.slice(0, -2)
const GASPRICE = 0.00000001
const WRITETXGASLIMIT = 80000
const READTXGASLIMIT = 10000
const TTL = 28800

// id of network version
// const networkId = "testnet04";

// creation time for request
const creationTime = () => Math.round(new Date().getTime() / 1000) - 15

// unique contract name
const hftNamespace = 'marmalade'
const hftContractName = 'ledger'
const hftConstants = {}

// unique contract name
const manifestNamespace = 'kip'
const manifestContractName = 'token-manifest'
const manifestConstants = {}

const gtpNamespace = 'marmalade'
const gtpContractName = 'guard-token-policy'
const gtpConstants = {}

// unique contract name
const fqpNamespace = 'marmalade'
const fqpContractName = 'fixed-quote-policy'
const fqpConstants = {}

const coawrpNamespace = process.env.NEXT_PUBLIC_COAWRNAMESPACE
const coawrpContractName = process.env.NEXT_PUBLIC_COAWRCONTRACTNAME
const coawrpConstants = {}

const coinContractName = 'coin'
const coinConstants = {
  coinFundKAccountAmount: 0.000000000001
}

// unique gas station contract name
const gasStationName = 'memory-wall-gas-station'

const host = (chainId = CHAINID) => {
  // network node
  let node = null
  let url = null
  switch (process.env.NEXT_PUBLIC_ENV) {
    case 'development':
      node = 'api.testnet.chainweb.com'
      // api host to send requests
      url = `https://${node}/chainweb/0.0/${NETWORKID}/chain/${chainId}/pact`
      break
    case 'staging':
      node = 'api.testnet.chainweb.com'
      // api host to send requests
      url = `https://${node}/chainweb/0.0/${NETWORKID}/chain/${chainId}/pact`
      break
    case 'production':
      node = 'api.chainweb.com'
      // api host to send requests
      url = `https://${node}/chainweb/0.0/${NETWORKID}/chain/${chainId}/pact`
      break
    case 'fauxpact':
      node = 'localhost:9001'
      // api host to send requests
      url = `http://${node}`
      break
    case 'local':
      node = 'api.testnet.chainweb.com'
      // api host to send requests
      url = `https://${node}/chainweb/0.0/${NETWORKID}/chain/${chainId}/pact`

      // For use when there's a local pact server. Keep around for when Kadena
      // releases the Ganache like tool.
      // node = "localhost:9001";
      // url = `http://${node}`;
      break
    default:
  }
  return url
}

const manifestAPI = {
  contractName: manifestContractName,
  gasStationName,
  namespace: manifestNamespace,
  contractAddress: `${manifestNamespace}.${manifestContractName}`,
  gasStationAddress: `${manifestNamespace}.${gasStationName}`,
  constants: manifestConstants
}

const fqpAPI = {
  contractName: fqpContractName,
  gasStationName,
  namespace: fqpNamespace,
  contractAddress: `${fqpNamespace}.${fqpContractName}`,
  gasStationAddress: `${fqpNamespace}.${gasStationName}`,
  constants: fqpConstants
}

const coawrpAPI = {
  contractName: coawrpContractName,
  gasStationName,
  namespace: coawrpNamespace,
  contractAddress: `${coawrpNamespace}.${coawrpContractName}`,
  gasStationAddress: `${coawrpNamespace}.${gasStationName}`,
  constants: coawrpConstants
}

const hftAPI = {
  contractName: hftContractName,
  gasStationName,
  namespace: hftNamespace,
  contractAddress: `${hftNamespace}.${hftContractName}`,
  gasStationAddress: `${hftNamespace}.${gasStationName}`,
  constants: hftConstants
}

const gtpAPI = {
  contractName: gtpContractName,
  gasStationName,
  namespace: gtpNamespace,
  contractAddress: `${gtpNamespace}.${gtpContractName}`,
  gasStationAddress: `${gtpNamespace}.${gasStationName}`,
  constants: gtpConstants
}

const coinAPI = {
  contractName: coinContractName,
  gasStationName,
  namespace: null,
  contractAddress: `${coinContractName}`,
  gasStationAddress: `${fqpNamespace}.${gasStationName}`,
  constants: coinConstants
}

const rotateAPI = {
  contractName: coinContractName,
  gasStationName,
  namespace: null,
  contractAddress: `${coinContractName}`,
  gasStationAddress: `${fqpNamespace}.${gasStationName}`,
  constants: coinConstants
}

const signers = {
  first: {
    publicKey: process.env.SIGNER_PUBLIC_KEY,
    secretKey: process.env.SIGNER_SECRET_KEY
  },
  second: {
    publicKey: process.env.SECOND_SIGNER_PUBLIC_KEY,
    secretKey: process.env.SECOND_SIGNER_SECRET_KEY
  },
  enforcer: {
    publicKey: process.env.ENFORCE_KEY,
    secretKey: process.env.ENFORCE_SECRET
  },
  sender: {
    publicKey: process.env.SENDER_PUBLIC_KEY,
    secretKey: process.env.SENDER_SECRET_KEY
  }
}

const gasCap = () => {
  return {
    name: 'coin.GAS',
    args: []
  }
}

const burnCap = (token, tokenOwner, amount) => {
  return {
    name: 'marmalade.ledger.BURN',
    args: [token, tokenOwner, Number.parseFloat(amount)]
  }
}

const mintCap = (token, tokenCreator, amount) => {
  return {
    name: 'marmalade.ledger.MINT',
    args: [token, tokenCreator, Number.parseFloat(amount)]
  }
}

const tokenTransferCap = (token, fromAccount, toAccount, amount) => {
  return {
    name: 'marmalade.ledger.TRANSFER',
    args: [token, fromAccount, toAccount, Number.parseFloat(amount)]
  }
}

const meta = (sender = '') => {
  return Pact.lang.mkMeta(
    sender,
    CHAINID,
    GASPRICE,
    WRITETXGASLIMIT,
    creationTime(),
    TTL
  )
}

const globalConfig = {
  networkId: NETWORKID,
  host: host(CHAINID),
  chainId: CHAINID,
  meta,
  creationTime,
  defaultChainId: CHAINID,
  defaultGasPrice: GASPRICE,
  defaultGasLimit: READTXGASLIMIT,
  defaultTTL: TTL,
  chainwebExplorerURL: `https://explorer.chainweb.com/${NETWORK}`
}

module.exports = { globalConfig, manifestAPI, hftAPI, gtpAPI, fqpAPI, coinAPI, coawrpAPI, rotateAPI, signers, burnCap, mintCap, tokenTransferCap, gasCap }
