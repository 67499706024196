/* eslint-disable react/prop-types */
import { React } from 'react'
import '../styles/global.css'
import { CookiesProvider } from 'react-cookie'
import { TorusProvider } from '../context/TorusContext'
import { NetworkProvider } from '../context/NetworkContext'
import { PactProvider } from '../context/PactContext'
import { AuthProvider } from '../context/AuthContext'
import { ActionCableProvider } from '../context/ActionCableContext'
import { SWRConfig } from 'swr'
import fetchJson from '../lib/fetchJson'
import Head from 'next/head'
import GoogleAnalytics from '../components/Utils/GoogleAnalytics'

function MyApp ({ Component, pageProps }) {
  return (
    <SWRConfig
      value={{
        fetcher: fetchJson,
        onError: (err) => {
          console.error(err)
        }
      }}
    >
      <Head>
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <title>DNA - Database of Native Assets</title>
      </Head>
      <GoogleAnalytics />
      <CookiesProvider>
        <TorusProvider>
          <NetworkProvider>
            <PactProvider>
              <AuthProvider>
                <ActionCableProvider>
                  <Component {...pageProps} />
                </ActionCableProvider>
              </AuthProvider>
            </PactProvider>
          </NetworkProvider>
        </TorusProvider>
      </CookiesProvider>
    </SWRConfig>

  )
}

export default MyApp

/* <Router>
  <Switch>
    <PrivateRoute path="/create-token">
      <CreateTokenPage />
    </PrivateRoute>
    <Route path="/terms-and-conditions">
      <TermsPage />
    </Route>
    <Route path="/privacy-policy">
      <PrivacyPage />
    </Route>
    <Route path="/artist/steve-olson">
      <ArtistProductListPage />
    </Route>
    <PrivateRoute path="/my-collection">
      <UserProductListPage />
    </PrivateRoute>
    <Route path="/art/:id">
      <ProductPage />
    </Route>
    <Route path="/sign-in">
      <LoginPage />
    </Route>
    <Route path="/">
      <LandingPage />
    </Route>
  </Switch>
  </Router> */
