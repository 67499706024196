/* eslint-disable react/prop-types */
import { createContext, useContext, useState, useEffect, React } from 'react'
import Pact from 'pact-lang-api'
import { TorusContext } from './TorusContext'
import { PactContext } from './PactContext'
import { authenticationAPI } from '../api/APILogin'
import fetchJson, { FetchError } from '../lib/fetchJson'
import useUser from '../lib/useUser'

export const AuthContext = createContext(null)

export const AuthProvider = (props) => {
  const pactContext = useContext(PactContext)
  const [loading, setLoading] = useState(false)
  const [loadingBalance, setLoadingBalance] = useState(false)
  const [totalBalance, setTotalBalance] = useState([])
  const torus = useContext(TorusContext)
  const { user } = useUser()
  const { mutateUser } = useUser()

  const setErrorMsg = useState('')

  const subVerifierDetailsGoogle = {
    // name: "Google",
    typeOfLogin: 'google',
    verifier: process.env.NEXT_PUBLIC_TORUS_GOOGLE_VERIFIER,
    clientId: process.env.NEXT_PUBLIC_TORUS_GOOGLE_CLIENT_ID
  }

  const subVerifierDetailsPasswordless = {
    // name: "passwordless",
    typeOfLogin: 'jwt',
    verifier: process.env.NEXT_PUBLIC_TORUS_AUTH0_VERIFIER,
    clientId: process.env.NEXT_PUBLIC_AUTH0_ID,
    jwtParams: {
      domain: process.env.NEXT_PUBLIC_AUTH0_DOMAIN,
      verifierIdField: 'email',
      connection: '',
      isVerifierIdCaseSensitive: false
    }
  }

  // The deployed verifier identifier links both google and facebook
  const commonVerifierIdentifier = process.env.NEXT_PUBLIC_TORUS_AGGREGATE

  useEffect(() => {
    // const init = async () =>
    // async function getBalanceWrapper() {
    const getBalanceWrapper = async () => {
      if (user?.rAccount && !loadingBalance) {
        setLoadingBalance(true)
        setTotalBalance(await pactContext.getBalance('coin', user.rAccount))
        setLoadingBalance(false)
      }
    }
    getBalanceWrapper()
  }, [user?.rAccount])

  const loginWithGoogle = async () => {
    if (user.isLoggedIn) {
      return console.debug('user is already logged in')
    }
    setLoading(true)
    try {
      await torus.torusdirectsdk.triggerAggregateLogin({
        aggregateVerifierType: 'single_id_verifier',
        verifierIdentifier: commonVerifierIdentifier,
        subVerifierDetailsArray: [subVerifierDetailsGoogle]
      })
    } catch (error) {
      console.error(error, 'error caught while signing in with google')
    } finally {
      setLoading(false)
    }
  }

  const loginWithEmail = async () => {
    if (user.isLoggedIn) {
      return console.debug('user is already logged in')
    }
    setLoading(true)
    try {
      await torus.torusdirectsdk.triggerAggregateLogin({
        aggregateVerifierType: 'single_id_verifier',
        verifierIdentifier: commonVerifierIdentifier,
        subVerifierDetailsArray: [subVerifierDetailsPasswordless]
      })
    } catch (error) {
      console.error(error, 'error caught while signing in with email')
    } finally {
      setLoading(false)
    }
  }

  const login = async (loginDetails, cb) => {
    try {
      const keyPair = Pact.crypto.restoreKeyPairFromSecretKey(
        loginDetails.privateKey
      )
      const namespace = process.env.NEXT_PUBLIC_NAMESPACE
      const { authTokenApi, userType } = await authenticationAPI({ email: loginDetails?.userInfo[0].email, kadena_account: `${keyPair.publicKey}` })
      if (
        process.env.NEXT_PUBLIC_ENV === 'local' ||
        process.env.NEXT_PUBLIC_ENV === 'development'
      ) {
        console.debug('keypair:', keyPair)
      }
      const body = {
        username: loginDetails?.userInfo[0].name,
        email: loginDetails?.userInfo[0].email,
        wsToken: loginDetails?.userInfo[0].email,
        publicAddress: loginDetails?.publicAddress,
        publicKey: keyPair.publicKey,
        secretKey: keyPair.secretKey,
        kAccount: `k:${keyPair.publicKey}`,
        rAccount: `r:${namespace}._${keyPair.publicKey}`,
        authTokenApi,
        userType,
        isLoggedIn: true
      }
      try {
        mutateUser(
          await fetchJson('/api/login', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(body)
          })
        )
      } catch (error) {
        if (error instanceof FetchError) {
          setErrorMsg(error.data.message)
        } else {
          console.error('An unexpected error happened:', error)
        }
      }
    } catch (error) {
      console.error(error, 'error caught in login - method called in /auth')
    } finally {
      setLoading(false)
      cb()
    }
  }

  const logout = async (cb) => {
    mutateUser(
      await fetchJson('/api/logout', { method: 'POST' }),
      false
    )
    cb()
  }

  return (
    <AuthContext.Provider
      value={{ user, loading, loginWithEmail, loginWithGoogle, logout, totalBalance, login }}
    >
      {props.children}
    </AuthContext.Provider>
  )
}
