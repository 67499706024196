// src/providers/action_cable_provider.js

import { createContext, useEffect, useState, useContext, React } from 'react'
import { globalConfig } from '../api/APIConfig'
import { AuthContext } from './AuthContext'

const ActionCableContext = createContext()

// eslint-disable-next-line react/prop-types
const ActionCableProvider = ({ children }) => {
  const auth = useContext(AuthContext)
  const [CableApp, setCableApp] = useState({})

  const loadConsumer = async () => {
    const { createConsumer } = await import('@rails/actioncable')
    return createConsumer
  }

  useEffect(() => {
    if (typeof window !== 'undefined' && auth.user?.authTokenApi && CableApp.cable === undefined) {
      loadConsumer().then((createConsumer) => {
        setCableApp({
          cable: createConsumer(`${globalConfig.API_WS_ROOT}?token=${auth.user?.authTokenApi}`)
        })
      })
    }
  }, [auth.user])

  return <ActionCableContext.Provider value={CableApp.cable}>{children}</ActionCableContext.Provider>
}

export { ActionCableContext, ActionCableProvider }
