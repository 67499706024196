/* eslint-disable react/prop-types */
import { createContext, React } from 'react'
import Pact from 'pact-lang-api'
import { globalConfig } from '../pact/kadena-config'
import _ from 'lodash'

export const PactContext = createContext(null)

export const addGasCap = (otherCaps) => _.concat([Pact.lang.mkCap('Gas Cap', 'Gas Cap', 'coin.GAS', [])], otherCaps)

export const PactProvider = (props) => {
  const { meta, host } = globalConfig
  const getAcctDetails = async (tokenAddress, acct, chainId) => {
    try {
      // this function only READS from the blockchain
      const data = await Pact.fetch.local(
        {
          pactCode: `(${tokenAddress}.details ${JSON.stringify(acct)})`,
          keyPairs: Pact.crypto.genKeyPair(),
          meta: meta()
        },
        host
      )

      if (data.result.status === 'success') {
        // account exists
        // return {account: string, guard:obj, balance: decimal}
        return data.result.data
      } else {
        // account does not exist
        return null
      }
    } catch (e) {
      // most likely a formatting or rate limiting error
      console.debug('PactContext - Error fetching Kadena account details: ', e)
      return null
    }
  }

  const getBalance = async (tokenAddress, userAddress) => {
    try {
      // get balance for all 20 chains
      const balances = []

      for (let i = 0; i < 20; i++) {
        const chainId = i.toString()
        const acctDetails = await getAcctDetails(
          tokenAddress,
          userAddress,
          chainId
        )
        if (acctDetails) {
          balances[chainId] = acctDetails.balance
        } else {
          balances[chainId] = undefined
        }
      }
      return balances
    } catch (e) {
      console.debug('PactContext - Error in getBalance: ', e)
      return null
    }
  }

  return (
    <PactContext.Provider value={{ getBalance }}>
      {props.children}
    </PactContext.Provider>
  )
}
