import { globalConfig } from './APIConfig'

const sessionAPI = async (loginToken) => {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ login_token: loginToken })
  }
  const response = await fetch(
    `${globalConfig.host}/v1/sessions/create`,
    requestOptions
  ).catch(function (error) {
    console.debug('Error in Session API', error)
  })
  const data = await response.json()
  return data
}

export const authenticationAPI = async (props) => {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ user: { email: props.email, kadena_account: props.kadena_account } })
  }
  const response = await fetch(
    `${globalConfig.host}/v1/authentication/create`,
    requestOptions
  ).catch(function (error) {
    console.debug('Error in Authentication API', error)
    return false
  })

  const data = await response.json()
  const sessionData = await sessionAPI(data.login_token)
  return { authTokenApi: sessionData.auth_token, userType: sessionData.user_type }
}
