const host = () => {
  // network node
  let url = null
  switch (process.env.NEXT_PUBLIC_ENV) {
    case 'development':
      url = 'https://api.dev.thedna.tech'
      break
    case 'staging':
      url = 'https://api.staging.thedna.tech'
      break
    case 'production':
      url = 'https://api.thedna.tech'
      break
    case 'fauxpact':
      url = 'http://localhost:3001'
      break
    case 'local':
      url = 'http://localhost:3001'
      break
    default:
  }
  return url
}

// const PARSE_JSON = res => res.json()
// const HEADERS = {
//   'Content-Type': 'application/json',
//   Accept: 'application/json'
// }

const globalConfig = {
  host: host(),
  API_WS_ROOT: process.env.NEXT_PUBLIC_WSS
}

module.exports = { globalConfig }
