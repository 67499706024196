/* eslint-disable react/prop-types */
import React, { createContext, useState } from 'react'

export const MAINNET = {
  name: 'mainnet',
  label: 'Mainnet',
  networkID: process.env.NEXT_PUBLIC_KADENA_NETWORK_ID_MAINNET,
  kadenaServer: process.env.NEXT_PUBLIC_KADENA_SERVER_MAINNET
}

export const TESTNET = {
  name: 'testnet',
  label: 'Testnet',
  networkID: process.env.NEXT_PUBLIC_KADENA_NETWORK_ID_TESTNET,
  kadenaServer: process.env.NEXT_PUBLIC_KADENA_SERVER_TESTNET
}

// const savedNetwork = localStorage.getItem("network");
const savedNetwork = null

export const NetworkContext = createContext(null)

export const NetworkProvider = (props) => {
  const [network, setNetwork] = useState(
    savedNetwork ? JSON.parse(savedNetwork) : TESTNET
  )

  const toggleNetwork = () => {
    switch (network.name) {
      case MAINNET.name:
        setNetwork(TESTNET)
        // localStorage.setItem("network", JSON.stringify(TESTNET));
        break
      case TESTNET.name:
        setNetwork(MAINNET)
        // localStorage.setItem("network", JSON.stringify(MAINNET));
        break
      default:
        console.debug('ERROR: toggle network error')
        break
    }
  }
  return (
    <NetworkContext.Provider value={{ network, toggleNetwork }}>
      {props.children}
    </NetworkContext.Provider>
  )
}
