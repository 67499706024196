/* eslint-disable react/prop-types */
import { createContext, useState, useEffect, React } from 'react'
// import TorusSdk from "@toruslabs/torus-direct-web-sdk";
import DirectWebSdk from '@toruslabs/customauth'
export const TorusContext = createContext(null)

export const TorusProvider = (props) => {
  const [torusdirectsdk, setTorusdirectsdk] = useState(null)

  useEffect(() => {
    const init = async () => {
      const torusdirectsdkProperties = {
        baseUrl: `${window.location.origin}`,
        enableLogging: false,
        redirectPathName: 'auth',
        uxMode: 'redirect',
        network: process.env.NEXT_PUBLIC_TORUS_NETWORK
      }
      // if(process.env.NEXT_PUBLIC_ENV !== "production"){
      //   torusdirectsdkProperties = { ...torusdirectsdkProperties, networkUrl: "https://still-side-haze.ropsten.discover.quiknode.pro/679a74283c1c6aeb91ee314d0c9bf841dcb1da53/" }
      // }
      try {
        const torusDirectSdk = new DirectWebSdk(torusdirectsdkProperties)
        await torusDirectSdk.init({ skipSw: true })

        setTorusdirectsdk(torusDirectSdk)
      } catch (error) {
        console.error(error, 'mounted caught')
      }
    }
    init()
  }, [])

  return (
    <TorusContext.Provider value={{ torusdirectsdk }}>
      {props.children}
    </TorusContext.Provider>
  )
}
